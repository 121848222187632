<template>
  <transition name="slide-fade">
    <b-overlay :show="!loadingComplete" rounded="sm">
      <b-card bg-variant="gray-200" border-variant="Secondary" class="mt-5">
        <!--begin::Response Title-->
        <GenericGroup
          v-if="requiredTitle"
          id="title"
          :model="$v.form.title"
          :label="computedTitleInputs.label"
          :showInline="false"
          :submissionStates="submissionStates"
          :error="computedTitleInputs.error"
        ></GenericGroup>
        <!--end::Response Title-->

        <!--begin::Response Message-->
        <GenericTextArea
          v-if="requiredResponse"
          id="response"
          :label="computedResponseInputs.label"
          :model="$v.form.response"
          :submissionStates="submissionStates"
          :error="computedResponseInputs.error"
        >
        </GenericTextArea>
        <!--end::Response Message-->

        <!--Begin: Action Button ---->
        <div class="mt-10 d-flex justify-content-between">
          <button class="btn btn-white" @click="actionReturn('cancel')">
            {{ $t("RESPONSE_FORMS.BUTTON.CANCEL") }}
          </button>
          <button class="btn btn-primary" @click="handleMessage">
            {{ actionButtonText }}
          </button>
        </div>
        <!--End: Action Button ---->
      </b-card>
    </b-overlay>
  </transition>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";

import Swal from "sweetalert2";
import PageLoader from "@/core/classes/PageLoader";
import GenericTextArea from "../generic-inputs/GenericTextArea";
import GenericGroup from "../generic-inputs/GenericInputGroup";

export default {
  name: "ForumResponseBox",

  props: {
    mode: { type: String, required: true },
    responseType: { type: String, required: false },
    dataMessageText: { type: String, required: false },
    dataTitle: { type: String, required: false },
    dataRespondingTo: { type: Number, required: false },
  },

  components: {
    GenericTextArea,
    GenericGroup,
  },

  data: function () {
    return {
      form: {
        title: "",
        response: "",
        respondingTo: false,
      },

      pageLoader: new PageLoader(0),

      submissionStates: {
        submitting: false,
      },
    };
  },

  validations: {
    form: {
      title: {
        required: requiredIf(function () {
          return this.requiredTitle;
        }),
      },
      response: {
        required: requiredIf(function () {
          return this.requiredResponse;
        }),
      },

      respondingTo: {
        required: requiredIf(function () {
          return this.mode == "post-comment";
        }),
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    preparePayload() {
      let data = {
        project_profile_id: this.currentUser.project_profiles.id, // required
        user_id: this.currentUser.id, // required
        description: this.form.response,
        reply: this.form.response,
        title: this.form.title,
      };
      if (this.mode == "post-comment" || this.responseType == "ResponseReply") {
        data.repliable_type = this.repliable_type;
        data.repliable_id = this.dataRespondingTo;
      }

      let payload = {
        projectId: this.projectInfo.id,
        projectForumId: this.projectForumInfo.id,
        data: data,
      };
      
      if (this.mode == "update") {
        payload.responseId = this.dataRespondingTo;
      }
      return payload;
    },

    handleMessage() {
      this.pageLoader.addRequiredComponent();
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        this.pageLoader.setCompleteComponent();
        return;
      }

      let payload = this.preparePayload();
      this.submissionStates.submitting = true;

      this.$store
        .dispatch(this.moduleActionName, payload)
        .then((res) => {
          this.setProjectForumResponseInfo(res);
          this.afterHandlingMessage();
          this.pageLoader.setCompleteComponent();
        })
        .catch((error) => {
          this.setProjectForumErrors(error);
        });
    },

    afterHandlingMessage() {
      let sweetAlertMsg = "";

      if (this.postingReply || this.isParticipant) {
        sweetAlertMsg = this.$t("RESPONSE_FORMS.SUCCESS.MESSAGE");
      }

      if (!this.postingReply && !this.isParticipant) {
        sweetAlertMsg = this.$t("RESPONSE_FORMS.ADD_SUCCESS.TEST_ACTIVITY");
      }

      Swal.fire({
        title: "",
        text: sweetAlertMsg,
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
        allowOutsideClick: false,
      }).then(() => {
        this.actionReturn("success");
      });
    },

    actionReturn(result) {
      switch (result) {
        case "success":
          this.$emit("cancelAction");
          this.$emit("refreshList");
          break;

        case "cancel":
          this.$emit("cancelAction");
          break;
      }

      this.resetInput();
    },

    resetInput() {
      this.submissionStates.submitting = false;
      this.$v.$reset();
      this.form.title = "";
      this.form.response = "";
      this.form.respondingTo = false;
    },

    ...mapActions({
      createProjectForumResponse: "createProjectForumResponse",
      createProjectForumResponseReply: "createProjectForumResponseReply",
      updateProjectForumResponse: "updateProjectForumResponse",
      updateProjectForumResponseReply: "updateProjectForumResponseReply",
    }),

    ...mapMutations({
      setProjectForumResponseInfo: "setProjectForumResponseInfo",
      setProjectForumErrors: "setProjectForumErrors",
    }),
  },

  watch: {
    mode: {
      handler(val) {
        this.resetInput();
        if (val == "update") {
          this.form.title = this.dataTitle;
          this.form.response = this.dataMessageText;
          this.form.respondingTo = this.dataRespondingTo;
        } else if (val == "post-comment") {
          this.form.respondingTo = this.dataRespondingTo;
        }
      },
    },
  },

  computed: {
    ...mapGetters(["currentUser", "projectInfo", "projectForumInfo"]),

    loadingComplete: function () {
      return this.pageLoader.getStatus();
    },

    requiredTitle() {
      return true;
    },

    computedTitleInputs() {
      if (this.requiredTitle) {
        let label = this.$t("RESPONSE_FORMS.INPUTS.TITLE");
        let error = this.$t("RESPONSE_FORMS.ERROR.TITLE");
        return { label: label, error: error };
      } else {
        return false;
      }
    },

    computedResponseInputs() {
      if (this.requiredResponse) {
        let label = this.$t("RESPONSE_FORMS.INPUTS.MESSAGE_TEXT");
        let error = this.$t("RESPONSE_FORMS.ERROR.MESSAGE_TEXT");
        return { label: label, error: error };
      } else {
        return false;
      }
    },

    requiredResponse: function () {
      return true;
    },

    actionButtonText() {
      let text = "";
      switch (this.mode) {
        case "post":
          text = "RESPONSE_FORMS.BUTTON.POST";
          break;
        case "update":
          text = "RESPONSE_FORMS.BUTTON.EDIT";
          break;
        case "post-comment":
          text = "RESPONSE_FORMS.BUTTON.REPLY";
          break;
        default:
          text = "RESPONSE_FORMS.BUTTON.POST";
          break;
      }
      return `${this.$t(text)}`;
    },

    moduleActionName() {
      let action = "";
      switch (this.mode) {
        case "post":
          action = `createProjectForumResponse`;
          break;
        case "update":
          if (this.responseType == "BaseResponse") {
            action = `updateProjectForumResponse`;
          } else if (this.responseType == "ResponseReply") {
            action = `updateProjectForumResponseReply`;
          }
          break;
        case "post-comment":
          action = `createProjectForumResponseReply`;
          break;
      }
      return action;
    },

    repliable_type() {
      if (this.responseType == "ResponseReply") {
        return "ForumResponseReply";
      } else {
        return "ForumResponse";
      }
    },

    postingReply: function () {
      if (this.mode == "post-comment" || this.responseType == "ResponseReply") {
        return true;
      } else {
        return false;
      }
    },

    isParticipant: function () {
      if (this.currentUser.relation_to_project == "participant") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
