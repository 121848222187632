<template>
  <div>
    <b-form-group
      :id="id + 'group'"
      :label="computedLabel"
      :label-for="id"
      :label-sr-only="!displayLabel"
      class="w-100 mb-0"
      :class="computedLabelAlignment"
      :label-cols-sm="computedLabelColsSM"
      :label-cols-lg="computedLabelColsLG"
    >
      <b-input-group
        size="md"
        :prepend="computedPrepend"
        :append="computedAppend"
      >
        <b-form-input
          :type="computedType"
          :id="id"
          :name="id"
          v-model="model.$model"
          :state="validateState()"
          v-on:keyup.enter="nextElementFocus($event)"
          :aria-describedby="'feedback-' + id"
          :placeholder="computedPlaceholder"
          :disabled="computedDisabled"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <div class="custom-validation-failure" v-if="model.$error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericInputGroup",

  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    label: { type: String, required: false },
    alignLabel: { type: String, required: false },
    placeholder: { type: String, required: false },
    error: { type: String, required: false },
    showInline: { type: Boolean, required: false },
    submissionStates: { type: Object, required: true },
    type: { type: String, required: false },
    prepend: { type: String, required: false },
    append: { type: String, required: false },
    helper: { type: String, required: false },
    disabled: { type: Boolean, required: false }
  },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    },
    nextElementFocus() {
      this.$emit("nextInputFocus", this.id);
    }
  },

  computed: {
    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayError: function() {
      if (this.error != undefined) {
        if (this.error.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    computedLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          if (this.model.$params.hasOwnProperty("required")) {
            return this.label + "*";
          } else {
            return this.label;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    computedPlaceholder: function() {
      if (this.placeholder != undefined) {
        if (this.placeholder.length > 0) {
          if (this.model.$params.hasOwnProperty("required")) {
            return this.placeholder + "*";
          } else {
            return this.placeholder;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    computedLabelColsSM: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 4;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelColsLG: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 3;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelAlignment: function() {
      if (this.alignLabel != undefined) {
        if (this.alignLabel == "right") {
          return "text-right";
        } else if (this.alignLabel == "center") {
          return "text-center";
        } else {
          return "text-left";
        }
      } else {
        return "text-left";
      }
    },
    computedType: function() {
      if (this.type != undefined) {
        return this.type;
      } else {
        return "text";
      }
    },
    computedPrepend: function() {
      if (this.prepend != undefined) {
        return this.prepend;
      } else {
        return null;
      }
    },
    computedAppend: function() {
      if (this.append != undefined) {
        return this.append;
      } else {
        return null;
      }
    },
    computedDisabled: function() {
      if (this.disabled != undefined) {
        return this.disabled;
      } else if (this.submissionStates.submitting === true) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
