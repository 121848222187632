<template>
  <b-card class="card card-custom gutter-b" header-tag="header">
    <template #header>
      <h5 class="mb-0" v-html="forumHeader(projectForumInfo)"></h5>
    </template>

    <b-card-text class="font-size-normal">{{
      projectForumInfo.description
    }}</b-card-text>

    <slot name="forum-panel"></slot>
  </b-card>
</template>

<script>

export default {
  name: "GeneralForumDisplay",

  data() {
    return {};
  },

  props: {
    projectForumInfo: { type: Object, required: true },
  },

  components: {
  },

  computed: {},

  methods: {
    forumHeader(projectForumInfo) {
      return `${projectForumInfo.topic}`;
    },
  },
};
</script>
