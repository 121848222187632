<template>
  <!--begin::Loading Spinner-->
  <div class="row" v-if="pageLoader.getStatus() == false">
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
  <GeneralForumDisplay v-else :projectForumInfo="projectForumInfo">
    <div slot="forum-panel">
      <div class="mt-10 d-flex flex-row-reverse">
        <button
          class="btn btn-primary"
          @click="toggleNewMessage()"
          v-show="!showNewMessageBox"
        >
          {{ $t("RESPONSE_FORMS.INPUTS.NEW_REPLY") }}
        </button>
      </div>

      <!--begin::Message input Box-->
      <InputForumResponseBox
        mode="post"
        :baseResponse="true"
        v-show="showNewMessageBox"
        @cancelAction="toggleNewMessage"
        @refreshList="fetchForumTopic"
      ></InputForumResponseBox>
      <!--end::Message input Box-->

      <!--begin::Response Lists-->
      <div class="mt-10">
        <template v-for="(reply, index) in projectForumResponseList">
          <forumResponse
            :layer="index"
            :reply="reply"
            :targetResponse="responseId"
            v-bind:key="index"
            :baseResponse="true"            
            @deleteResponse="deleteResponse($event)"
            @deleteComment="deleteComment($event)"
            @refreshList="fetchForumTopic"
          ></forumResponse>
        </template>
      </div>
      <!--end::Response Lists-->
    </div>
  </GeneralForumDisplay>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import forumResponse from "@/modules/together-helpers/components/forumResponse";
import GeneralForumDisplay from "@/view/components/activity/GeneralForumDisplay.vue";
import InputForumResponseBox from "@/modules/together-helpers/components/specialised-inputs/forumResponseBox";
import PageLoader from "@/core/classes/PageLoader";

export default {
  name: "ViewForumTopic",

  components: {
    forumResponse,
    GeneralForumDisplay,
    InputForumResponseBox,
  },

  data() {
    return {
      pageLoader: new PageLoader(1),
      showNewMessageBox: false,
    };
  },

  mounted() {
    this.cleanup();
    this.fetchForumTopic();
  },

  watch: {
    projectForumId: function () {
      this.pageLoader.addRequiredComponent();
      this.fetchForumTopic();
    },
  },

  methods: {
    fetchForumTopic: function () {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/forums/${this.projectForumId}`,
        params: {
          paginate: false,
        },
      };

      this.$store
        .dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          this.setProjectForumInfo(res);
          this.fetchProjectForumResponse();
          this.pageLoader.setCompleteComponent();
        })
        .catch((error) => {
          this.setProjectForumErrors(error);
        });
    },
    fetchProjectForumResponse: function () {
      this.pageLoader.addRequiredComponent();

      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/forums/${this.projectForumId}/forum_responses`,
        params: {
          paginate: false,
          replies: "with",
          sentiments: "with",
        },
      };

      this.$store
        .dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          this.setProjectForumResponseList(res._embedded.forum_responses);
          this.pageLoader.setCompleteComponent();
        })
        .catch((error) => {
          this.setProjectForumResponseErrors(error);
        });
    },

    deleteResponse($event) {
      let id = $event[0];
      let baseResponse = $event[1];

      let payload = {
        projectId: this.projectInfo.id,
        projectForumId: this.projectForumId,
        id: id,
      };

      if (baseResponse == true) {
        this.deleteProjectForumResponse(payload)
          .then(() => {
            this.$toasted.global.success_toast({
              message: this.$t("TOASTS.DELETE_REPLY.SUCCESS"),
            });
            this.fetchForumTopic();
          })
          .catch(() => {
            this.error.active = true;
            this.error.message =
              "We're having some issues deleting forum response, please check back later or contact the helpdesk";
          });
      }
    },
    deleteComment($event) {
      let id = $event[0];
      let baseResponse = $event[1];

      let payload = {
        projectId: this.projectInfo.id,
        projectForumId: this.projectForumId,
        id: id,
      };

      this.deleteProjectForumResponseReply(payload)
        .then(() => {
          this.$toasted.global.success_toast({
            message: this.$t("TOASTS.DELETE_REPLY.SUCCESS"),
          });
          this.fetchForumTopic();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting forum response, please check back later or contact the helpdesk";
        });
    },
    toggleNewMessage() {
      this.showNewMessageBox = !this.showNewMessageBox;
    },
    cleanup: function () {
      this.setProjectForumInfo([]);
      this.setProjectForumResponseList([]);
      this.setProjectForumResponseSentimentList([]);
    },

    ...mapActions({
      deleteProjectForumResponse: "deleteProjectForumResponse",
      deleteProjectForumResponseReply: "deleteProjectForumResponseReply",
    }),

    ...mapMutations({
      setProjectForumInfo: "setProjectForumInfo",
      setProjectForumResponseList: "setProjectForumResponseList",
      setProjectForumResponseSentimentList:
        "setProjectForumResponseSentimentList",
      setProjectForumErrors: "setProjectForumErrors",
      setProjectForumResponseErrors: "setProjectForumResponseErrors",
    }),
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectForumInfo",
      "projectForumResponseList",
    ]),

    projectForumId: function () {
      return this.$route.params.id;
    },

    loadingComplete: function () {
      return this.pageLoader.getStatus();
    },
  },
};
</script>